'use client';
import { Button, Flex, Title } from '@mantine/core';
import Link from 'next/link';

function NotFound() {
  return (
    <Flex
      align={'center'}
      justify={'center'}
      mih={'100%'}
      direction={'column'}
      gap={'md'}
      w={'100%'}
      h={'100%'}
    >
      <Title>Not Found</Title>
      <Link href={'/'} passHref>
        <Button component={'a'} variant='white'>
          Return Home
        </Button>
      </Link>
    </Flex>
  );
}

function NotPermitted() {
  return (
    <Flex
      align={'center'}
      justify={'center'}
      mih={'100%'}
      direction={'column'}
      gap={'md'}
      w={'100%'}
      h={'100%'}
    >
      <Title>Not Permitted</Title>
      <Link href={'/'} passHref>
        <Button component={'a'} variant='white'>
          Return Home
        </Button>
      </Link>
    </Flex>
  );
}

export default function Error({ error }: { error: Error }) {
  switch (error.name) {
    case 'NotFoundError':
      return <NotFound />;
    case 'NoTenantPermissionError':
      return <NotPermitted />;
  }

  return (
    <Flex
      align={'center'}
      justify={'center'}
      direction={'column'}
      gap={'md'}
      mih={'75vh'}
    >
      <Title>Something When Wrong!</Title>
      <Link href={'/'} passHref>
        <Button component={'a'} variant={'white'}>
          Return Home
        </Button>
      </Link>
    </Flex>
  );
}
